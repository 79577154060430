import React, { useState, useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import _ from 'lodash'
import HamburgerMenu from 'react-hamburger-menu'
import { useWindowScroll, useLockBodyScroll, useWindowSize } from 'react-use'

import Link from './Link'

import './Header.css'

const Header = ({ inverted = false }) => {
  const data = useStaticQuery(graphql`
    {
      pages: allMarkdownRemark(
        filter: { frontmatter: { template: { eq: "Page" } } }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  `)
  const pages = _.get(data, 'pages.edges' || [])

  const [menuButtonMargin] = useState(40)
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => setMenuOpen(!menuOpen)
  useLockBodyScroll(menuOpen)
  const { height: windowHeight } = useWindowSize()
  const { y: windowY } = useWindowScroll()

  const NavLink = (props) => (
    <Link onClick={() => setMenuOpen(false)} className="NavLink" {...props} />
  )

  const menuButtonColor = useMemo(() => {
    if (menuOpen) {
      return 'white'
    }
    if (!inverted) {
      return 'black'
    }
    if (windowY > windowHeight - menuButtonMargin * 2) {
      return 'black'
    }
    return 'white'
  }, [windowY, menuOpen, inverted, menuButtonMargin, windowY, windowHeight])

  return (
    <div
      className={`Header ${inverted ? 'Header-inverted' : ''} ${
        menuOpen ? 'Header-open' : ''
      }`}
    >
      <div className="section thin Header--Section">
        <div className="container skinny Header--Container">
          <div className="Header--Nav">
            <NavLink to="/">Home</NavLink>
            {pages.map((page) => (
              <NavLink
                key={`page-${page.node.fields.slug}`}
                to={page.node.fields.slug}
              >
                {page.node.frontmatter.title}
              </NavLink>
            ))}
            <NavLink to="/contact/">Contact</NavLink>
            <NavLink to="https://samvallen.bandcamp.com">Bandcamp</NavLink>
            <NavLink to="https://www.youtube.com/watch?v=H0HiFaLhDB8">
              YouTube
            </NavLink>
            <NavLink to="https://www.instagram.com/samvallenmusic/">
              Instagram
            </NavLink>
          </div>
        </div>
      </div>
      <div
        className="Header--MenuButton"
        style={{
          top: menuButtonMargin,
          right: menuButtonMargin,
        }}
      >
        <HamburgerMenu
          isOpen={menuOpen}
          menuClicked={toggleMenu}
          width={24}
          height={18}
          strokeWidth={2}
          rotate={0}
          color={menuButtonColor}
          borderRadius={5}
          animationDuration={0.5}
        />
      </div>
    </div>
  )
}

export default Header
