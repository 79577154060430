import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

import './Link.css'

const Link = ({
  children,
  to,
  className = '',
  activeClassName = 'active',
  ...other
}) => {
  const internal = /^\/(?!\/)/.test(to)
  className = `Link ${className}`

  if (internal) {
    return (
      <GatsbyLink
        to={to}
        className={className}
        activeClassName={activeClassName}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} className={className} {...other}>
      {children}
    </a>
  )
}

export default Link
