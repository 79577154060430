import React from 'react'
import Helmet from 'react-helmet'
import Konami from 'react-konami'

import Footer from './Footer'
import 'modern-normalize/modern-normalize.css'
import '../globalStyles.css'
import './Layout.css'

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:500,600,700,800"
          rel="stylesheet"
        />
      </Helmet>

      {children}

      <Footer />
      <Konami
        easterEgg={() => {
          document.body.classList.add('konami')
        }}
      />
    </>
  )
}

export default Layout
