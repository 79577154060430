import React from 'react'

import './Footer.css'

export default () => (
  <footer className="Footer section thin">
    <div className="container">
      © {new Date().getFullYear()} Sam Vallen, Site by
      {` `}
      <a href="https://ericjinks.com">Eric</a>
    </div>
  </footer>
)
